import { Tab, TabList, Tabs } from "@chakra-ui/react"
import _ from "lodash"
import React from "react"
import useColors from "../hooks/useColors"

const TabsComp = ({
  onTabChange,
  tabsAr,
  isReversed = false,
  curTab,
  ...props
}) => {
  const { orangeColor } = useColors()
  const tabsArray = isReversed ? _.reverse(tabsAr) : tabsAr
  const index = props.index || tabsAr.findIndex((tab) => tab.value === curTab)

  return (
    <Tabs
      fontFamily={"heading"}
      colorScheme="brandWeighted.orangeColor"
      onChange={(ind) => {
        onTabChange(tabsArray[ind].value)
      }}
      index={index}
      {...props}
    >
      <TabList>
        {tabsArray.map((t) => (
          <Tab key={t.value}>{t.name ?? t.label}</Tab>
        ))}
      </TabList>
    </Tabs>
  )
}

export default TabsComp
