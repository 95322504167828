import _ from "lodash"
import {
  frenchBoulderingGrades,
  usBoulderingGrades,
  frenchRouteGrades,
  usRouteGrades,
} from "../config/grades"
import {
  addMissingProblemParameters,
  addMissingRouteParameters,
} from "./offline"

// use isNotAbove for gyms that use a grade system
export function closestValue(array, value, isNotAbove) {
  let result
  let lastDelta

  // TODO: clean up
  array.some((item) => {
    if (isNotAbove && item >= value) {
      result = item
      return true
    }
    const delta = Math.abs(value - item)
    if (!isNotAbove && delta > lastDelta) {
      return true
    }
    result = item
    lastDelta = delta
    return false
  })
  return result
}

export function getNearestGradeObj(array, grade, isNotAbove) {
  const val = closestValue(
    (array || []).map((obj) => obj.value),
    grade,
    isNotAbove,
  )
  return _.find(array, { value: val })
}

// get's min grade number for scale
export function getMinGradeNumber({ grade, climbType, gradeType, gym }) {
  const isShowingGrades = !gym || gym?.isShowingGrades
  const collection = getGradeScale(climbType, gradeType, gym)
  const nearest = getNearestGradeObj(collection, grade)
  const gradeInd =
    nearest && collection.findIndex((obj) => obj.value === nearest.value)
  if (gradeInd === 0) {
    return 1
  }
  const oldValue = nearest.value
  const newValue = collection[gradeInd - 1].value
  return newValue + (isShowingGrades ? (oldValue - newValue) / 2 : 1)
}

export function getMaxGradeNumber({ grade, climbType, gradeType, gym }) {
  const isShowingGrades = !gym || gym?.isShowingGrades
  const collection = getGradeScale(climbType, gradeType, gym)
  const nearest = getNearestGradeObj(collection, grade)
  if (isShowingGrades) {
    return nearest?.value
  }
  const gradeInd = collection.findIndex((obj) => obj.value === nearest.value)
  const oldValue = nearest?.value ?? 0
  const newValue = collection[gradeInd]?.value || collection[1]?.value
  return newValue - (newValue - oldValue) / 2
}

export function getMaxGradeObject({ grade, climbType, gradeType, gym }) {
  const isShowingGrades = !gym || gym?.isShowingGrades
  const collection = getGradeScale(climbType, gradeType, gym)
  const nearest = getNearestGradeObj(collection, grade)
  if (!!isShowingGrades) {
    return nearest
  }
  return collection.find((obj) => obj.value === nearest.value)
}

export const getGradeLabel = (
  grade,
  type,
  gradeType,
  gym,
  gradeColor,
  gradeName,
) => {
  if (!grade || grade === 0) {
    return ""
  }
  const obj = getGradeObject(grade, type, gradeType, gym, gradeColor, gradeName)
  return obj?.label || ""
}

export const getGradeObject = (
  grade,
  type,
  gradeType,
  gym,
  gradeColor,
  gradeName,
  isSetter = false,
) => {
  let collection
  if (gym) {
    collection = getGradeCollectionFromGym({
      gym,
      climbType: type,
      isSetter,
    })
    const possibleObj = gradeColor
      ? collection.find((obj) => obj.color === gradeColor)
      : gradeName
      ? collection.find((obj) => obj.label === gradeName)
      : null
    const nearestObj = getNearestGradeObj(
      collection,
      grade,
      !gym.isShowingGrades,
    )
    return possibleObj || nearestObj
  }
  collection = getGradeScale(type, gradeType)
  return getNearestGradeObj(collection, grade)
}

export const getGradeFromObject = (obj) => {
  if (!obj.gym?._id) {
    throw new Error(`A gym is required to find a ${obj.type}'s grade`)
  }
  let climb
  if (obj.type === "problem" || obj.type === "route") {
    climb = obj
  }
  if (obj.type === "send") {
    climb = obj.problem || obj.route
  }
  if (!climb?._id) {
    throw new Error(`A climb is required to find a ${obj.type}'s grade`)
  }
  const gym = obj.gym ?? climb.gym
  return getGradeObject(
    climb.setterGrade || climb.grade,
    climb.type,
    gym.isFrenchGrades ? "french" : "us",
    gym,
    climb.gradeColor,
    climb.gradeName,
  )
}

export const getGradeColorRangeFromIndexes = ({
  minGradeColor,
  maxGradeColor,
  gradeScale,
}) => {
  const minIndex = gradeScale.findIndex((obj) => obj.color === minGradeColor)
  const maxIndex = gradeScale.findIndex((obj) => obj.color === maxGradeColor)
  return gradeScale.slice(minIndex, maxIndex + 1)
}

export const getGradeNameRangeFromIndexes = ({
  minGradeName,
  maxGradeName,
  gradeScale,
}) => {
  const minIndex = gradeScale.findIndex((obj) => obj.name === minGradeName)
  const maxIndex = gradeScale.findIndex((obj) => obj.name === maxGradeName)
  return gradeScale.slice(minIndex, maxIndex + 1)
}

export const getRangeAroundGrades = ({
  gradesAr,
  type,
  gradeType,
  gym,
  color,
  name,
}) => {
  const minGrade = _.min(gradesAr.map((g) => g.grade))
  const maxGrade = _.max(gradesAr.map((g) => g.grade))
  let allGrades = getGradeScale(type, gradeType, gym)
  let minGradeInd
  let maxGradeInd
  let gradeRange
  if (gym?.isOverlappingGrades) {
    // get grade color in all grades and just get 1 before and after that
    const gradeColorInd = allGrades.findIndex((g) =>
      color ? g.color === color : g.name === name,
    )
    gradeRange = allGrades.slice(
      Math.max(0, gradeColorInd - 1),
      Math.max(gradeColorInd + 2, 1),
    )
  } else {
    const closestMinVal = closestValue(
      allGrades.map((g) => g.value),
      minGrade,
    )
    minGradeInd = allGrades.map((g) => g.value).indexOf(closestMinVal) - 1
    const closestMaxVal = closestValue(
      allGrades.map((g) => g.value),
      maxGrade,
      true,
    )
    maxGradeInd = allGrades.map((g) => g.value).indexOf(closestMaxVal) + 2
    gradeRange = _.slice(
      allGrades,
      Math.max(0, minGradeInd),
      Math.min(maxGradeInd, allGrades.length),
    )
  }
  return gradeRange
}

export const getGradeScale = (type, gradeType, gym, isSetter) => {
  if (gym) {
    return getGradeCollectionFromGym({ gym, climbType: type, isSetter })
  }
  switch (type) {
    case "problem":
      return gradeType === "french"
        ? frenchBoulderingGrades.map((gr) => ({
            ...gr,
            maxGrade: gr.value,
            name: gr.label,
          }))
        : usBoulderingGrades.map((gr) => ({
            ...gr,
            maxGrade: gr.value,
            name: gr.label,
          }))
    case "route":
      return gradeType === "french"
        ? frenchRouteGrades.map((gr) => ({
            ...gr,
            maxGrade: gr.value,
            name: gr.label,
          }))
        : usRouteGrades.map((gr) => ({
            ...gr,
            maxGrade: gr.value,
            name: gr.label,
          }))
    default:
      return false
  }
}

export function getGradeColor(value, colors, gradeColor) {
  let color
  if (!colors) {
    return
  }
  colors.some((col) => {
    if (col.maxGrade >= value && (!gradeColor || col.color === gradeColor)) {
      color = col.color
      return true
    } else {
      return false
    }
  })
  return color
}

export function getGradeImage(value, colors) {
  let image
  if (!colors) {
    return
  }
  colors.some((col) => {
    if (col.maxGrade >= value) {
      image = col.image
      return true
    } else {
      return false
    }
  })
  return image
}

export function getGradeColorName(value, colors) {
  let name
  if (!colors) {
    return
  }
  colors.some((col) => {
    if (col.maxGrade >= value) {
      name = col.name
      return true
    } else {
      return false
    }
  })
  return name
}

export function getGradeColorObject(value, colors) {
  let obj
  if (!colors) {
    return
  }
  colors.some((col) => {
    if (col.maxGrade >= value) {
      obj = col
      return true
    } else {
      return false
    }
  })
  return obj
}

export function getColorRange(gradeRange, colors) {
  const colorObjs = gradeRange.map((grade) => {
    return getGradeColorObject(grade.value, colors)
  })
  return _.uniqBy(colorObjs, (obj) => obj.color)
}
export function getImageRange(gradeRange, colors) {
  const colorObjs = gradeRange.map((grade) => {
    return getGradeColorObject(grade.value, colors)
  })
  return _.uniqBy(colorObjs, (obj) => obj.image)
}

export function getGradeMenuItems({ gym, climbType, gradeType }) {
  const isRoute = climbType === "route" || climbType === "routes"
  if (gym?.problemGradeColors || gym?.routeGradeColors) {
    return isRoute ? gym?.routeGradeColors : gym?.problemGradeColors
  }
  return isRoute
    ? gradeType === "french"
      ? frenchRouteGrades
      : usRouteGrades
    : gradeType === "french"
    ? frenchBoulderingGrades
    : usBoulderingGrades
}

export function getGradeCollectionFromGym({
  gym: initGym,
  climbType,
  isSetter = false,
}) {
  const gym = initGym?.toObject ? initGym.toObject() : initGym
  if (!gym) {
    return []
  }
  // just a precaution. ran into this issue and will take a while to clean up
  const isRoute = climbType === "route" || climbType === "routes"
  if (
    gym.isShowingGrades ||
    (isSetter && !gym.isShowingCustomGradesToSetters)
  ) {
    const collection = gym.isFrenchGrades
      ? isRoute
        ? frenchRouteGrades
        : frenchBoulderingGrades
      : isRoute
      ? usRouteGrades
      : usBoulderingGrades
    return collection.map((gr) => ({
      ...gr,
      maxGrade: gr.value,
      name: gr.label,
    }))
  }
  // get gym grade system
  const gradeSystem = isRoute
    ? gym?.routeGradeColors?.length
      ? gym.routeGradeColors
      : gym.isFrenchGrades
      ? frenchRouteGrades
      : usRouteGrades
    : gym?.problemGradeColors?.length
    ? gym.problemGradeColors
    : gym.isFrenchGrades
    ? frenchBoulderingGrades
    : usBoulderingGrades
  return gradeSystem.map((gr) => ({
    ...gr,
    label: gr.name,
    value: gr.maxGrade,
    minValue: gr.minGrade,
  }))
}

export function getAnyGymProblems(gradeType, userId) {
  return (gradeType === "french" ? frenchBoulderingGrades : usBoulderingGrades)
    .filter((gr) => gr.value !== 100)
    .map((gr) =>
      addMissingProblemParameters(
        {
          // _id: gr.value,
          __typename: "Problem",
          name: `${gr.label}`,
          grade: gr.value,
          isIndoor: true,
          type: "problem",
          publishedAt: new Date(),
          color: "#03A1F9",
          points: Math.round(gr.value * 13.6),
          isGrade: true,
          numUserSends: 0,
          tries: 0,
        },
        userId,
      ),
    )
}

export function getAnyGymRoutes(gradeType, userId) {
  return (gradeType === "french" ? frenchRouteGrades : usRouteGrades)
    .filter((gr) => gr.value !== 100 && gr.value > 4)
    .map((gr) =>
      addMissingRouteParameters(
        {
          // _id: gr.value,
          __typename: "Route",
          name: `${gr.label}`,
          grade: gr.value,
          isIndoor: true,
          type: "route",
          publishedAt: new Date(),
          color: "#03A1F9",
          points: Math.round(gr.value * 10),
          isGrade: true,
          numUserSends: 0,
          tries: 0,
        },
        userId,
      ),
    )
}

export function getSendGrade(send) {
  const climb = send[send.sendType]
  return climb?.setterGrade || climb?.grade || send.grade
}

export function getGradeColorIndex({ gradeColor, gradeColors }) {
  const colors = gradeColors.map((col) => col.color)
  return colors.indexOf(gradeColor)
}

export function getGradeNameIndex({ gradeName, gradeColors }) {
  const colors = gradeColors.map((col) => col.name || col.label)
  return colors.indexOf(gradeName)
}

export function getMaxSendGradeName({ sends, gym }) {
  if (!gym?.isOverlappingGrades) {
    return false
  }
  // get index of send gradeColors
  const climbs = sends.map((send) => send[send.sendType])
  if (!climbs?.[0]) {
    return false
  }
  const maxSendGradeNameInd = _.max(
    climbs.map((climb) => {
      const ind = getGradeNameIndex({
        gradeName: climb.gradeName,
        gradeColors: gym[`${climb.type}GradeColors`],
      })
      return ind
    }),
  )
  return gym[`${climbs[0].type}GradeColors`][maxSendGradeNameInd]?.name
}

export function getMaxSendGradeColor({ sends, gym }) {
  if (!gym?.isOverlappingGrades) {
    return false
  }
  // get index of send gradeColors
  const climbs = sends.map((send) => send[send.sendType])
  if (!climbs?.[0]) {
    return false
  }
  const maxSendGradeColorInd = _.max(
    climbs.map((climb) => {
      const ind = getGradeColorIndex({
        gradeColor: climb.gradeColor,
        gradeColors: gym[`${climb.type}GradeColors`],
      })
      return ind
    }),
  )
  return gym[`${climbs[0].type}GradeColors`][maxSendGradeColorInd]?.color
}

// gets a new range from a max grade and optional min grade based on the grade scale
// used in pyramids and recommendations
export function getGradeRange({
  sendType,
  gradeType,
  gym: initGym,
  maxGrade: maxG = 3,
  minGrade: minG = undefined,
  isPlusOne = false,
  isMinusOne = false,
}) {
  minG = minG || 0
  maxG = maxG || (sendType === "route" ? 10 : 5)
  const gym = initGym && initGym?.slug !== "any-gym" ? initGym : null
  const gradeScale = getGradeScale(sendType, gradeType, gym)
  const maxGradeObj = getGradeObject(maxG, sendType, gradeType, gym)
  const maxGradeInd = Math.min(
    gradeScale.findIndex((obj) => obj.value === maxGradeObj.value) +
      (isPlusOne ? 1 : 0),
    gradeScale.length - 1,
  )
  let maxGrade = isPlusOne ? gradeScale[maxGradeInd].value : maxG
  let minGrade
  let minGradeInd
  if (minG) {
    minGrade = minG
    const minGradeObj = getGradeObject(minGrade, sendType, gradeType, gym)
    minGradeInd = Math.max(
      gradeScale.findIndex((obj) => obj.value === minGradeObj.value),
      0,
    )
  } else {
    const diff = !gym || gym?.isShowingGrades ? 4 : 2
    minGradeInd = Math.max(maxGradeInd - diff - (isMinusOne ? 1 : 0), 0)
    minGrade = gradeScale[minGradeInd]?.value || 0
  }
  const numRows = maxGradeInd - minGradeInd + 1
  return {
    maxGrade,
    minGrade,
    maxGradeInd,
    minGradeInd,
    numRows,
    gradeScale,
  }
}

export function getGradeColorRange({
  sendType,
  gradeType,
  gym,
  maxGradeColor: maxGC = undefined,
  minGradeColor: minGC = undefined,
  maxGradeName: maxGN = undefined,
  minGradeName: minGN = undefined,
  isPlusOne = false,
  isMinusOne = false,
}) {
  minGC = minGC || gym[`${sendType}GradeColors`][0].color
  maxGC = maxGC || gym[`${sendType}GradeColors`][1].color
  minGN = minGN || gym[`${sendType}GradeColors`][0].name
  maxGN = maxGN || gym[`${sendType}GradeColors`][1].name
  const maxGradeFromColor = gym[`${sendType}GradeColors`].find((obj) => {
    return obj.color ? obj.color === maxGC : obj.name === maxGN
  }).maxGrade
  const gradeScale = getGradeScale(sendType, gradeType, gym)
  const maxGradeObj = getGradeObject(
    maxGradeFromColor,
    sendType,
    gradeType,
    gym,
    maxGC,
    maxGN,
  )
  const maxGradeInd = Math.min(
    gradeScale.findIndex((obj) => obj?.value === maxGradeObj?.value) +
      (isPlusOne ? 1 : 0),
    gradeScale.length - 1,
  )
  let maxGradeColor = isPlusOne ? gradeScale[maxGradeInd].color : maxGC
  let minGradeColor
  let minGradeName
  let minGradeInd
  if (minGC || minGN) {
    minGradeColor = minGC
    minGradeName = minGN
    const minGradeFromColor = gym[`${sendType}GradeColors`].find((obj) =>
      obj.color ? obj.color === minGC : obj.name === minGN,
    ).minGrade
    const minGradeObj = getGradeObject(
      minGradeFromColor,
      sendType,
      gradeType,
      gym,
      minGradeColor,
      minGradeName,
    )
    minGradeInd = Math.max(
      gradeScale.findIndex((obj) => obj.value === minGradeObj.value),
      0,
    )
  } else {
    const diff = !gym || gym?.isShowingGrades ? 4 : 2
    minGradeInd = Math.max(maxGradeInd - diff - (isMinusOne ? 1 : 0), 0)
    minGradeColor = gradeScale[minGradeInd]?.color || gradeScale[0].color
    minGradeName = gradeScale[minGradeInd]?.name || gradeScale[0].name
  }
  const numRows = maxGradeInd - minGradeInd + 1
  return {
    maxGradeColor,
    minGradeColor,
    maxGradeInd,
    minGradeInd,
    numRows,
    gradeScale,
  }
}

// adjusts range from above based on both user min and max grades and guide min
// and max grades
export function getRecommendedRange({
  feature,
  loggedInUser,
  gradeType,
  climbType,
  isPlusOne = true,
}) {
  const isGym = feature.type === "gym"
  const isShowingGrades = !isGym || !feature.isShowingGrades
  const isProblem = climbType === "problem"
  const gym = isGym ? feature : null
  const minFeatureGrade = isProblem
    ? feature.minProblemGrade
    : feature.minRouteGrade
  const maxFeatureGrade = isProblem
    ? feature.maxProblemGrade
    : feature.maxRouteGrade
  const hardestOutdoorUserGrade = isProblem
    ? loggedInUser.hardestCurrentOutdoorProblemSendGrade
    : loggedInUser.hardestCurrentOutdoorRouteSendGrade
  const hardestIndoorUserGrade = isProblem
    ? loggedInUser.hardestCurrentIndoorProblemSendGrade
    : loggedInUser.hardestCurrentIndoorRouteSendGrade
  const hardestUserGrade = isGym
    ? hardestIndoorUserGrade || hardestOutdoorUserGrade
    : hardestOutdoorUserGrade || hardestIndoorUserGrade
  const diff = isShowingGrades ? 3 : 1
  const minFGrade = minFeatureGrade || 1
  isPlusOne = minFGrade < hardestUserGrade && isPlusOne
  const userGrade = Math.max(hardestUserGrade, minFGrade + diff)
  const hardestGrade = Math.min(userGrade, maxFeatureGrade || 100)

  return getGradeRange({
    sendType: climbType,
    gradeType,
    gym,
    maxGrade: hardestGrade,
    minGrade: minFeatureGrade,
    isPlusOne,
  })
}

export function getOutdoorGradeFromLabel({ label, climbType }) {
  const isRoute = climbType === "route"
  const grades = isRoute
    ? [...frenchRouteGrades, ...usRouteGrades]
    : [...frenchBoulderingGrades, ...usBoulderingGrades]
  return grades.find((gr) => {
    return gr.label.toLowerCase() === label.toLowerCase()
  })
}

export function getGymAreaDistributionGrades(climbType, gymArea) {
  const gradeType = !!gymArea?.gym?.isFrenchGrades ? "french" : "us"
  let outdoorGrades
  if (climbType === "problem") {
    outdoorGrades =
      gradeType === "french"
        ? frenchBoulderingGrades.filter((gr) => gr.value !== 100)
        : usBoulderingGrades.filter((gr) => gr.value !== 100)
  }
  if (climbType === "route") {
    outdoorGrades =
      gradeType === "french"
        ? frenchRouteGrades.filter((gr) => gr.value !== 100)
        : usRouteGrades.filter((gr) => gr.value !== 100)
  }
  const customGrades = gymArea?.gym?.isShowingGrades
    ? []
    : gymArea?.gym?.[`${climbType}GradeColors`]
  // if custom grades, then merge with outdoor grades
  if (!customGrades?.length) {
    return outdoorGrades
  }
  let ret
  if (gymArea?.gym?.isOverlappingGrades) {
    ret = customGrades.reduce((acc, obj) => {
      const grades = [
        {
          value: 1,
          label: "VB",
        },
        ...outdoorGrades,
      ]
        .filter((gr) => gr.value >= obj.minGrade && gr.value <= obj.maxGrade)
        .map((gr) => ({
          ...gr,
          name: obj.name,
          color: obj.color,
        }))
      return [...acc, ...grades]
    }, [])
    const customGradeCols = customGrades.map((obj) => obj.color || obj.name)
    ret = _.orderBy(
      ret,
      (obj) => customGradeCols.indexOf(obj.color ? obj.color : obj.name),
      "asc",
    )
  } else {
    // combine custom grades with outdoor grades
    // if custom grades are below outdoor grades then they'll be added to beginning (VB, etc.)
    // if always showing custom grades, then just use custom grades
    ret = gymArea?.gym?.isShowingCustomGradesToSetters
      ? customGrades.map((obj) => ({
          ...obj,
          value: obj.maxGrade,
          label: obj.name,
        }))
      : [...outdoorGrades, ...customGrades]
          .reduce((acc, obj) => {
            const isOutdoor = !!obj.value
            if (isOutdoor) {
              return [...acc, obj]
            }
            const minVal = _.minBy(outdoorGrades, (o) => o.value)?.value
            if (obj.maxGrade < minVal) {
              return [
                ...acc,
                {
                  value: obj.maxGrade,
                  label: obj.name,
                },
              ]
            }
            return acc
          }, [])
          .sort((a, b) => a.value - b.value)
  }

  return ret
}

export const getMaxGrade = (gymAreaMax, grades) => {
  if (!!gymAreaMax) {
    return _.maxBy(
      grades.filter((gr) => gr.value <= gymAreaMax),
      "value",
    )
    // return _.find(grades, (g) => g.value === gymAreaMax)
  }
  return _.chain(grades)
    .maxBy((g) => g.value)
    .value()
}

export const getMinGrade = (gymAreaMin, grades) => {
  if (!!gymAreaMin) {
    return _.minBy(
      grades.filter((gr) => gr.value >= gymAreaMin),
      "value",
    )
    // return _.find(grades, (g) => g.value === gymAreaMin)
  }
  return _.chain(grades)
    .minBy((g) => g.value)
    .value()
}

export const gymGradesWithNumBetween = ({
  gymGrades,
  gradesToShow,
  isOverlapping,
}) => {
  let curMaxValue = -1
  return gymGrades.map((g) => {
    const newG = {
      ...g,
      numOfGrades: gradesToShow.filter((gr) => {
        const isBetween =
          gr.value > curMaxValue && gr.value <= g.maxGrade && !isOverlapping
        const isOverlappingInBetween = gr.name === g.name && isOverlapping
        return isBetween || isOverlappingInBetween
      }).length,
    }
    curMaxValue = g.maxGrade
    return newG
  })
}
