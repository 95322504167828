import React from "react"
import {
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react"
import Card from "../../common/components/Card"

import styles from "./css/ConfirmModal.module.css"

const ModalCard = (props) => {
  const {
    isOpen,
    children,
    closeString = "Close",
    closeModal,
    contentStyle = {},
    isThin = false,
    ActionBtn = null,
    isHidingClose = false,
  } = props

  return (
    <Modal
      onClose={() => {
        closeModal()
      }}
      isOpen={isOpen === true}
    >
      <ModalOverlay />
      <ModalContent bg={"brand.whiteColor"} width={"90vw"} maxWidth="100vw">
        <Card>
          {children}
          <Flex mt={5} justifyContent="flex-end">
            {!isHidingClose && (
              <Button variant="secondary" onClick={closeModal}>
                {closeString}
              </Button>
            )}
            {ActionBtn}
          </Flex>
        </Card>
      </ModalContent>
    </Modal>
  )
}

export default ModalCard
