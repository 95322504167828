export const genderOptions = [
  {
    value: "male",
    label: "Man",
  },
  {
    value: "female",
    label: "Woman",
  },
  {
    value: "nonBinary",
    label: "Non-Binary",
  },
  {
    value: "preferNone",
    label: "Other",
  },
]
