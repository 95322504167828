import React, { Fragment } from "react"
import {
  MenuButton,
  MenuList,
  Menu,
  MenuItem,
  Portal,
  Button,
  Heading,
  IconButton,
  useTheme,
} from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/pro-light-svg-icons"
import useColors from "../../common/hooks/useColors"

const MenuBut = ({
  current = null,
  isDisabled = false,
  isLoading = false,
  options,
  onSelect,
  placeholder = "",
  renderItem = null,
  isText = true,
  buttonProps = {},
  size = "md",
  isError = false,
  isIcon = false,
  icon = null,
}) => {
  const { blueColor } = useColors()
  const isSections = !!options?.[0]?.title
  const sections = isSections
    ? options
    : [
        {
          options,
        },
      ]
  const sz = buttonProps?.size || size
  return (
    <Menu>
      <MenuButton
        isLoading={isLoading}
        overflow={"hidden"}
        {...(isError && { border: "2px solid red", borderRadius: "4px" })}
        mt={0.2}
        size={size}
        px={3}
        variant={isText ? "text" : "secondary"}
        as={isIcon ? IconButton : Button}
        disabled={isDisabled}
        icon={
          isIcon
            ? icon ?? <FontAwesomeIcon color={blueColor} icon={faAngleDown} />
            : null
        }
        rightIcon={
          isIcon ? null : (
            <FontAwesomeIcon color={blueColor} icon={faAngleDown} />
          )
        }
        fontSize={sz === "sm" ? "1rem" : sz === "md" ? "1.25rem" : "1.875rem"}
        {...buttonProps}
      >
        {current?.label || current || placeholder}
      </MenuButton>
      <Portal>
        <MenuList
          borderColor="brand.headingLineColor"
          bg="brand.whiteColor"
          maxHeight={300}
          overflow="auto"
          position="relative"
          zIndex={10000}
        >
          {sections.map((section, index) => (
            <Fragment key={section.title || index}>
              {section.title && (
                <Box key={section.title} bg={"brand.bgColor"} px={15} py={1}>
                  <Heading mb={0} as="h6" size="md">
                    {section.title}
                  </Heading>
                </Box>
              )}
              {section.options.map((option) => (
                <MenuItem
                  bg="brand.whiteColor"
                  sx={{
                    "&:hover": {
                      bg: "brand.bgColor",
                    },
                  }}
                  onClick={() => {
                    onSelect(option)
                  }}
                  key={option.value || option}
                >
                  <Heading
                    as="h6"
                    size="sm"
                    mb={0}
                    variant={
                      option.value && option.value === current?.value
                        ? "error"
                        : "link"
                    }
                  >
                    {renderItem ? renderItem(option) : option.label ?? option}
                  </Heading>
                </MenuItem>
              ))}
            </Fragment>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  )
}

export default MenuBut
