import {
  MenuButton,
  MenuList,
  Menu as MenuContainer,
  MenuItem,
  forwardRef,
  Box,
  Button,
  Heading,
  Portal,
  MenuDivider,
} from "@chakra-ui/react"
const DefaultButton = forwardRef((props, ref) => (
  <Box ref={ref}>
    <Button {...props} />
  </Box>
))

const Menu = ({ buttonComp = DefaultButton, items, buttonProps }) => {
  return (
    <MenuContainer>
      <MenuButton
        aria-label="Select an option"
        as={buttonComp ?? DefaultButton}
        {...buttonProps}
      />
      <Portal>
        <MenuList
          bg={"brand.whiteColor"}
          borderColor="brand.headingLineColor"
          maxHeight={300}
          overflow="auto"
          position="relative"
          zIndex={10000}
        >
          {items.map(({ label, onClick, isDisabled }, ind) =>
            label === "divider" ? (
              <MenuDivider key={label + ind} />
            ) : (
              <MenuItem
                bg={"brand.whiteColor"}
                key={label + ind}
                onClick={onClick}
                sx={{
                  ":hover": {
                    bg: "brand.bgColor",
                  },
                }}
              >
                <Heading
                  size="sm"
                  mb={0}
                  variant={isDisabled ? null : "link"}
                  as="h5"
                >
                  {label}
                </Heading>
              </MenuItem>
            ),
          )}
        </MenuList>
      </Portal>
    </MenuContainer>
  )
}

export default Menu
