import Tooltip from "../../common/components/Tooltip"
import React from "react"
import {
  getGradeLabel,
  getGradeColor,
  getGradeImage,
} from "../../utilities/grades"

import styles from "./css/ClimbGrade.module.css"
import { Box, Heading } from "@chakra-ui/react"
import useColors from "../hooks/useColors"

const ClimbGrade = ({
  climb,
  gym: initGym = null,
  gradeType = null,
  dotSize = 40,
  isCenter = false,
  ttId = "",
  isBold = false,
  isSetter = false,
}) => {
  const { headingLineColor } = useColors()
  const gym = initGym || climb.gym
  const isShowingGrades = !gym || gym?.isShowingGrades
  const grade = climb.setterGrade || climb.grade
  const gradeLabel = getGradeLabel(
    grade,
    climb.type,
    gradeType,
    gym,
    climb.gradeColor,
    climb.gradeName,
  )
  const gradeColor = gym
    ? climb.gradeColor ||
      getGradeColor(grade, gym?.[`${climb.type}GradeColors`])
    : null
  const setterGradeLabel = getGradeLabel(grade, climb.type, gradeType)
  const gradeImage = getGradeImage(grade, gym?.[`${climb.type}GradeColors`])
  return (
    <Box
      as="span"
      position="relative"
      display="flex"
      justifyContent={isCenter ? "center" : "flex-end"}
    >
      <Box as="span">
        {gradeImage && (
          <img
            width={dotSize}
            height={dotSize}
            src={gradeImage}
            alt={gradeLabel}
          />
        )}
        <Box
          as="span"
          display="flex"
          justifyContent={isCenter ? "center" : "flex-end"}
          alignItems="center"
          width={
            isShowingGrades || isSetter || !gradeColor ? null : `${dotSize}px`
          }
          height={isShowingGrades ? null : `${dotSize}px`}
          borderRadius={isShowingGrades || isSetter ? 3 : dotSize / 2}
          overflow={gradeColor ? "hidden" : "visible"}
          position={"relative"}
          borderColor={isShowingGrades ? "transparent" : headingLineColor}
          borderWidth={gradeColor ? 1 : 0}
        >
          <Box
            position={"absolute"}
            top={0}
            left={0}
            bottom={0}
            right={0}
            opacity={isShowingGrades || isSetter ? 0.3 : 1}
            bg={gradeColor ?? "transparent"}
          />
          {(isShowingGrades || (!gradeImage && !gradeColor) || isSetter) && (
            <Heading
              textAlign={isCenter ? "center" : "right"}
              position="relative"
              zIndex={1}
              mb={0}
              fontSize={dotSize - 5}
            >
              {isSetter ? setterGradeLabel : gradeLabel}
            </Heading>
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default ClimbGrade
